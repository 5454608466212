<template>
  <div class="p-1">
    <div
      class="rounded bg-white h-100 col-12 p-0"
      style="display: grid; align-content: space-between"
    >
      <p class="px-2 m-0 pt-2 text-gray">{{ title }}</p>
      <gauge-c3 :options="options" class="col-12 px-0 pb-1" />
    </div>
  </div>
</template>
<script>
import gaugeC3 from "@/components/charts/c3-chart/gauge-c3.vue";
export default {
  components: { gaugeC3 },
  props: {
    // options: Object,
    title: String,
    value: {
      type: Number,
      default: 34,
    },
    units: {
      type: String,
      default: "Application",
    },
    gaugeType: {
      type: String,
      default: "value",
      //other type is percentage
    },
  },
  data() {
    return {
      options: {
        data: {
          columns: [["data", this.value]],
          type: "gauge",
        },
        gauge: {
          label: {
            format:
              this.gaugeType === "value"
                ? function (value, ratio) {
                    ratio;
                    return value;
                  }
                : "",
            //show: false //
          },
          min: 0,
          max: 170,
          units: this.units,
          width: 39,
        },
        legend: {
          show: false,
        },
        color: {
          pattern: ["rgb(255, 0, 57)", "#FF0000", "#F97600", "#F6C600", "#60B044"],
          threshold: {
            unit: "value",
            values: [20, 30, 60, 90, 170],
          },
        },
        size: {
          height: 110,
        },
      },
    };
  },
};
</script>
<style>
.c3-chart-arcs .c3-chart-arcs-gauge-unit {
  font-size: 13px;
  padding-top: 4px;
}
</style>
