<template>
  <section class="guage-c3">
    <vue-c3 :handler="linecharthandler"></vue-c3>
  </section>
</template>

<script>
import Vue from "vue";
import VueC3 from "vue-c3";

export default {
  name: "guage-c3",
  components: {
    VueC3,
  },
  props: {
    options: Object,
  },

  data() {
    return {
      linecharthandler: new Vue(),
    };
  },

  mounted() {
    this.linecharthandler.$emit("init", this.options);
  },
};
</script>
