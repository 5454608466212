<template>
  <div :class="[' p-1 ', 'col-sm-12', 'col-md-6', 'col-xl']" style="cursor: pointer">
    <div
      class="card card-statistics h-100 text-white"
      :style="{ backgroundColor: color }"
    >
      <div class="p-3 m-0 h-100" style="display: grid; align-content: space-between">
        <div class="clearfix d-flex pb-1">
          <div class="my-auto">
            <i :class="`mdi icon-lg custom-icon-size ${icon}`"></i>
          </div>
          <div class="ml-auto my-auto">
            <div class="fluid-container">
              <h3 class="font-weight-medium text-right mb-0" style="font-size: 38px">
                {{ value }}
              </h3>
            </div>
          </div>
        </div>
        <div>
          <p class="mb-0 custom-title-size">
            {{ title }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    color: String,
    icon: String,
    value: String,
    title: String,
  },
};
</script>
