<template>
  <section class="content-wrapper p-0 m-0">
    <div class="d-flex my-1 p-1">
      <!-- <ListButtonActions id="securitedvp-section" class="ml-auto" /> -->
    </div>
    <b-tabs
      id="security"
      :value="$store.state.tabs.activeTab"
      @changed="activeTab = $store.state.tabs.activeTab"
      @activate-tab="(newTabIndex) => $store.dispatch('tabs/changeTab', newTabIndex)"
      class="tab-solid tab-solid-primary"
    >
      <b-tab title="SI" lazy>
        <div class="p-0 m-0 col-12">
          <div class="row row-eq-height p-0 m-0 col-12">
            <customGaugeChart
              :class="['col-sm-12', 'col-md-6', 'col-xl']"
              :value="255"
              title="Applications manipulant des données sensibles GDPR"
            />

            <analytics-statistics
              v-for="(item, index) in firstSection"
              :key="index"
              :color="item.color"
              :icon="item.icon"
              :title="item.title"
              :value="item.value"
            />
          </div>

          <div class="row row-eq-height p-0 m-0 col-12 mt-2">
            <!-- charts... -->

            <customBarChart
              :options="SensibiliteSiParDomaineOptions"
              title="Sensibilité du SI (DCP) par domaine"
              class="col-6"
            />
            <customBarChart
              :options="TauxSensibilitesSiOptions"
              title="Taux de sensibilité du SI (DCP)"
              class="col-6"
            />
          </div>
        </div>
      </b-tab>
      <b-tab title="SI EXTERNE" lazy>
        <div class="p-0 m-0 col-12">
          <div class="row row-eq-height p-0 m-0 col-12">
            <customGaugeChart
              :class="['col-sm-12', 'col-md-6', 'col-xl']"
              :value="255"
              title="Applications manipulant des données sensibles GDPR"
            />

            <analytics-statistics
              v-for="(item, index) in secondSection"
              :key="index"
              :color="item.color"
              :icon="item.icon"
              :title="item.title"
              :value="item.value"
            />
          </div>

          <div class="row row-eq-height p-0 m-0 col-12 mt-2">
            <customBarChart
              :options="SensibiliteSIExterneOptions"
              title="Sensibilité du SI externe"
              class="col-6"
            />
            <customBarChart
              :options="TauxSensibiliteSiExterneOptions"
              title="Taux de sensibilité (DCP) du SI externe"
              class="col-6"
            />
          </div>
        </div>
      </b-tab>
      <b-tab title="Autres" lazy>
        <div class="p-0 m-0 col-12">
          <div class="row row-eq-height p-0 m-0 col-12">
            <analytics-statistics
              color="rgb(255 199 132)"
              icon="mdi-account-remove "
              title="Applications DCP"
              value="170"
            />

            <customGaugeChart
              v-for="(item, index) in thirdSection"
              :key="index"
              :class="['col-sm-12', 'col-md-6', 'col-xl']"
              :value="item.value"
              :title="item.title"
            />
          </div>

          <div class="row row-eq-height p-0 m-0 col-12 mt-2">
            <!-- charts... -->
            <customBarChart
              :options="NatureTraitementDcpOptions"
              title="Nature de traitement DCP"
              class="col-md-6"
            />
            <customBarChart
              :options="TraitementDCPTypeHbergementOptions"
              title="Traitement DCP par type d'hébèrgement"
              class="col-md-12 col-xl-6"
            />
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>
import AnalyticsStatistics from "../components/AnalyticsStatistics.vue";
import customBarChart from "../components/customBarChart.vue";
import customGaugeChart from "../components/customGaugeChart.vue";
// import ListButtonActions from "../components/ListButtonActions.vue";

import {
  SensibiliteSiParDomaineOptions,
  TauxSensibilitesSiOptions,
  SensibiliteSIExterneOptions,
  TauxSensibiliteSiExterneOptions,
  //
  NatureTraitementDcpOptions,
  TraitementDCPTypeHbergementOptions,
} from "../chartsData.js";

export default {
  components: {
    customBarChart,
    AnalyticsStatistics,
    customGaugeChart,
    // ,
  },
  data() {
    return {
      firstSection: [
        {
          title: "Applications classifiées «Niveau Fort» de sensibilité",
          icon: "mdi-cogs",
          value: 20,
          color: "coral",
          link: "/transformation/initiative",
        },
        {
          title: "Applications classifiées «Niveau Moyen» de sensibilité",
          icon: "mdi-cogs",
          value: 47,
          color: "rgb(255, 203, 96)",
          link: "/transformation/deliverable",
        },
        {
          title: "Applications classifiées «Niveau Faible» de sensibilité",
          icon: "mdi-cogs",
          value: 37,
          color: "rgb(190, 245, 116)",
          link: "/strategy/goals",
        },
        {
          title: "Applications non auditées",
          icon: "mdi-cogs",
          value: 157,
          color: "rgb(68, 110, 155)",
          link: "/transformation/fdr",
        },
      ],
      secondSection: [
        {
          title: "Applications classifiées «Niveau Fort» de sensibilité",
          icon: "mdi-cogs",
          value: 20,
          color: "#003049",
          link: "/transformation/initiative",
        },
        {
          title: "Applications classifiées «Niveau Moyen» de sensibilité",
          icon: "mdi-cogs",
          value: 47,
          color: "#d62828",
          link: "/transformation/deliverable",
        },
        {
          title: "Applications classifiées «Niveau Faible» de sensibilité",
          icon: "mdi-cogs",
          value: 37,
          color: "#f77f00",
          link: "/strategy/goals",
        },
        {
          title: "Applications non auditées",
          icon: "mdi-cogs",
          value: 157,
          color: "#7209b7",
          link: "/transformation/fdr",
        },
      ],
      thirdSection: [
        {
          title: "Applications traitées : Purge",
          icon: "mdi-cogs",
          value: 20,
          color: "#003049",
          link: "/transformation/initiative",
        },
        {
          title: "Applications traitées : Anonymisation",
          icon: "mdi-cogs",
          value: 47,
          color: "#d62828",
          link: "/transformation/deliverable",
        },
        {
          title: "Applications traitées : Restriction d'accès",
          icon: "mdi-cogs",
          value: 37,
          color: "#f77f00",
          link: "/strategy/goals",
        },
        {
          title: "Applications non traitées",
          icon: "mdi-cogs",
          value: 157,
          color: "#7209b7",
          link: "/transformation/fdr",
        },
      ],
      SensibiliteSiParDomaineOptions: SensibiliteSiParDomaineOptions,
      TauxSensibilitesSiOptions: TauxSensibilitesSiOptions,
      SensibiliteSIExterneOptions: SensibiliteSIExterneOptions,
      TauxSensibiliteSiExterneOptions: TauxSensibiliteSiExterneOptions,
      //

      NatureTraitementDcpOptions,
      TraitementDCPTypeHbergementOptions,
    };
  },
};
</script>

<style>
#security .nav-item {
  background-color: white;
}
#security .custom-icon-size {
  font-size: 4rem;
}
#security .custom-title-size {
  font-size: 0.8rem;
}
#security .row > [class*="col-"] {
  display: flex;
  flex-direction: column;
}
#security .chart canvas {
  height: 100px !important;
  width: 100px;
}
</style>
